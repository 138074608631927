import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { graphql, navigate } from "gatsby";
import { useRecoilState, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { isNull, union, reduce, isEmpty, filter, includes } from "lodash";

import SpidImg from "../components/SpidImg/SpidImg";
import { selectedItemAtom, spidStepAtom, spidItemAtom } from "../utils/atoms";
import Layout from "../layout";

function SpidIntro({ data, location }) {
  const [selectedItem, setSelectedItem] = useRecoilState(selectedItemAtom);
  const setSpidStep = useSetRecoilState(spidStepAtom);
  const [spidItem, setSpidItem] = useRecoilState(spidItemAtom(selectedItem));

  if (!selectedItem || isNull(selectedItem)) {
    setSelectedItem(`sp-${uuidv4()}`);
  }

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [filteredList, setFilteredList] = useState(data.patternList.edges);
  const allTags = reduce(data.patternList.edges, (result, {node: { data: { tags }}}) => union(result, tags), [])
  const allColors = reduce(data.patternList.edges, (result, {node: { data: { colors }}}) => union(result, colors), [])

  useEffect(() => {
    const everything = data.patternList.edges
    const filtered = filter(everything, ({ node: { data: { colors, tags } } }) => { 
      if ( selectedColor && selectedCategory ) return includes(colors, selectedColor) && includes(tags, selectedCategory)
      if ( selectedColor )  return includes(colors, selectedColor)
      if ( selectedCategory )  return includes(tags, selectedCategory)
      return true; 
    });
    setFilteredList(filtered)
  }, [data.patternList.edges, selectedCategory, selectedColor]);

  return (
    <Layout location={location}>
      <>
        <Helmet title="Stuntpuppy" />

        <div className="flex flex-col h-screen justify-center items-center ">
          <div className="w-full h-full mt-32 mb-64 px-6">
            <div className="flex items-center mb-8 text-xl max-w-3xl">
              <div className="flex flex-col w-full mr-4">
                  <span className="uppercase font-bold text-sm mb-2">Category</span>
                  <select 
                    name="category" 
                    id="category"
                    className="rounded-full border-none mr-8 text-xl w-full"
                    value={selectedCategory}
                    onChange={e => setSelectedCategory(e.currentTarget.value)}
                  >
                    <option value="">All</option>
                    {allTags.map( tag => <option value={tag}>{tag}</option>)}
                  </select>
                </div>
                <div className="flex flex-col w-full">
                  <span className="uppercase font-bold text-sm mb-2">Color</span>
                  <select 
                    name="color" 
                    id="color"
                    className="rounded-full border-none text-xl w-full"
                    value={selectedColor}
                    onChange={e => setSelectedColor(e.currentTarget.value)}
                  >
                    <option value="">All</option>
                    {allColors.map( color => <option value={color}>{color}</option>)}
                  </select>
                </div>
            </div>
            <div className="flex h-full w-full items-center overflow-x-auto"> 
              {filteredList.map(
                ({
                  node: {
                    id,
                    data: { name, value, textColor, backgroundColor, file },
                  },
                }) => (
                    <div className="h-full flex-shrink-0 flex flex-col items-center"> 
                      <div key={id} className={`bg-white rounded-lg h-full px-10 mx-2 overflow-hidden border-4 ${
                          spidItem.pattern.value === value
                            ? "border-sp-orange"
                            : ""
                        }`}>
                        <label
                          className="block h-full shadow-xl"
                          htmlFor={value}
                        >
                          <input
                            className="hidden"
                            type="radio"
                            id={value}
                            name="pattern"
                            value={value}
                            checked={spidItem.pattern.value === value}
                            onChange={(e) =>{
                              setSpidItem({
                                ...spidItem,
                                textColor,
                                backgroundColor,
                                pattern: {
                                  label: name,
                                  value,
                                  img: file.localFiles[0].publicURL,
                                },
                              })
                            }}
                          />
                         {typeof window === "object" && file.localFiles[0].publicURL && <SpidImg
                            flip
                            sz={1}
                            pattern={file.localFiles[0].publicURL}
                            placeholder=""
                            size="7rem"
                          />}
                        </label>
                      </div>
                      <h3 className="uppercase font-bold text-sm">{name}</h3>
                  </div>
                )
              )}
            </div>
          </div>
          <button
              type="button"
              className={`${
                isEmpty(spidItem.pattern) ? "bg-opacity-20 bg-black cursor-not-allowed" : "bg-sp-orange"
              } fixed bottom-0 my-16 text-white rounded-full font-semibold text-3xl px-32 py-4`}
              disabled={isEmpty(spidItem.pattern)}
              onClick={() => {
                setSpidStep("spid-size")
                navigate("/spid-custom");
              }}
            >
              Next
            </button>
        </div>
      </>
    </Layout>
  );
}

export default SpidIntro;

export const query = graphql`
  {
    patternList: allAirtable(
      sort: { fields: rowIndex }
      filter: { table: { eq: "Patterns" }, data: { Active: { eq: true } } }
    ) {
      edges {
        node {
          id
          data {
            name: Name
            value: Value
            backgroundColor: Background_Color
            textColor: Text_Color
            tags: Tags
            colors: ColorFamily
            file: File {
              localFiles {
                id
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
